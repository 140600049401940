import "./style/EventPost.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  faClock,
  faCalendarDays,
  faLocationDot,
  faCirclePlus,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
function EventPost() {
  const [readMoreOpen, setReadMore] = useState(false);
  return (
    <div className="event-post-container">
      <div className="event-head">
        <div className="event-head-date">
          <p className="event-month">December</p>
          <p className="event-day">23</p>
        </div>
        <div className="event-head-details">
          <div className="event-detail">
            <div>
              {" "}
              <FontAwesomeIcon className="event-icons" icon={faClock} />
            </div>
            <p>9:00 AM</p>
          </div>
          <div className="event-detail">
            <div>
              {" "}
              <FontAwesomeIcon className="event-icons" icon={faCalendarDays} />
            </div>
            <p>2024/12/23</p>
          </div>
          <div className="event-detail">
            <div>
              <FontAwesomeIcon className="event-icons" icon={faLocationDot} />
            </div>
            <p className="p-location">
              University of Tripoli, Faculty of Science
            </p>
          </div>
        </div>
      </div>
      <div className="event-title">
        <h4>ACM Student Chapter launch </h4>
      </div>
      <div className="event-about">
        <div
          className="event-about-btn"
          onClick={() => setReadMore(!readMoreOpen)}
        >
          {" "}
          <span>Read More...</span>
          <FontAwesomeIcon
            className="about-icon"
            icon={readMoreOpen ? faCircleMinus : faCirclePlus}
          />
        </div>
      </div>
      <div className={readMoreOpen ? "read-p" : "read-p-none"}>
        <p>
          We are very excited to launch the first ACM Student Chapter in Libya.
          This event is an introduction to the ACM organization, the chapter
          teams, and how to join them. At the end of the event, there will also
          be a speaker with experience in the field will also be present Online
          with us to talk about the impact of student activities on students'
          future careers. Do not miss this opportunity. We will be waiting for
          you.
        </p>
      </div>
      <div className="event-background"></div>
    </div>
  );
}
export default EventPost;
